import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/layout';

const AgriCommodityPage = () => {
  return (
    <Layout pageTitle="Agri Commodity Trading" title="Agri Commodity Trading">
      <>
        <div className="row">
          <div className="col-lg-4 agro-product">
            <div className="row justify-content-center">
              <div className="col-auto" data-sal="fade" data-sal-easing="ease-out">
                <div className="text-center">
                  <StaticImage
                    className="agro-product-icon"
                    width={130}
                    height={130}
                    src="../images/icons/cereals-and-pulses.png"
                    alt="Cereals and pulses"
                  />
                </div>
                <h2 id="cereals">Cereals and pulses</h2>
                <ul>
                  <li>Chana</li>
                  <li>Barley</li>
                  <li>Bajra</li>
                  <li>Wheat</li>
                  <li>Moong</li>
                  <li>Maize Feed Industrial Grade</li>
                  <li>paddy (Basmati) - Pusa 1121</li>
                </ul>
                <a className="text-link-2 mb-3" href="/contact-us/#enquiry">To know more about trading or stock, click here</a>
              </div>
              <div className="col-12 d-block d-lg-none text-center mt-3" data-sal="zoom-in" data-sal-delay="100" data-sal-easing="ease-out">
                <StaticImage
                  width={300}
                  height={302}
                  src="../images/agri-commodity/cereals-and-pulses.png"
                  alt="Cereals and pulses"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 agro-product">
            <div className="row justify-content-center">
              <div className="col-auto" data-sal="fade" data-sal-easing="ease-out">
                <div className="text-center">
                  <StaticImage className="agro-product-icon" width={130} height={130} src="../images/icons/oil-and-seeds.png" alt="Oil and Seeds" />
                </div>
                <h2 id="oil-and-seeds">Oil and Oil Seeds</h2>
                <ul>
                  <li>Castor seed</li>
                  <li>Refined Castor Oil</li>
                  <li>Cotton Seed Oilcake</li>
                  <li>Soybean</li>
                  <li>Refined Soy Oil</li>
                  <li>Mustard Seed</li>
                  <li>Crude Palm Oil</li>
                  <li>Natural Whitish Sesame Seeds</li>
                  <li>Hipro Soybean Meal</li>
                </ul>
                <a className="text-link-2 mb-3" href="/contact-us/#enquiry">To know more about trading or stock, click here</a>
              </div>
              <div className="col-12 d-block d-lg-none text-center mt-3" data-sal="zoom-in" data-sal-delay="100" data-sal-easing="ease-out">
                <StaticImage
                  width={300}
                  height={302}
                  src="../images/agri-commodity/oil-and-seeds.png"
                  alt="Oil and Seeds"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 agro-product">
            <div className="row justify-content-center">
              <div className="col-auto" data-sal="fade" data-sal-delay="100" data-sal-easing="ease-out">
                <div className="text-center">
                  <StaticImage className="agro-product-icon" width={130} height={130} src="../images/icons/fibres.png" alt="Cereals and pulses" />
                </div>
                <h2 id="fibres">Fibres</h2>
                <ul>
                  <li>Kapas</li>
                  <li>29 MM Cotton</li>
                </ul>
                <a className="text-link-2 mb-3" href="/contact-us/#enquiry">To know more about trading or stock, click here</a>
              </div>
              <div className="col-12 d-block d-lg-none text-center mt-3" data-sal="zoom-in" data-sal-delay="100" data-sal-easing="ease-out">
                <StaticImage width={300} height={302} src="../images/agri-commodity/fibres.png" alt="Fibres" />
              </div>
            </div>
          </div>
        </div>
        <div className="row d-none d-lg-flex text-center mb-6 mt-3">
          <div className="col-lg-4" data-sal="zoom-in" data-sal-delay="150" data-sal-easing="ease-out">
            <StaticImage
              width={300}
              height={302}
              src="../images/agri-commodity/cereals-and-pulses.png"
              alt="Cereals and pulses"
            />
          </div>
          <div className="col-lg-4" data-sal="zoom-in" data-sal-delay="200" data-sal-easing="ease-out">
            <StaticImage
              width={300}
              height={302}
              src="../images/agri-commodity/oil-and-seeds.png"
              alt="Oil and Seeds"
            />
          </div>
          <div className="col-lg-4" data-sal="zoom-in" data-sal-delay="250" data-sal-easing="ease-out">
            <StaticImage width={300} height={302} src="../images/agri-commodity/fibres.png" alt="Fibres" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 agro-product">
            <div className="row justify-content-center">
              <div className="col-auto" data-sal="fade" data-sal-delay="100" data-sal-easing="ease-out">
                <div className="text-center">
                  <StaticImage className="agro-product-icon" width={130} height={130} src="../images/icons/spices.png" alt="Spices" />
                </div>
                <h2 id="spices">Spices</h2>
                <ul>
                  <li>Turmeric</li>
                  <li>Turmeric</li>
                  <li>Jeera</li>
                </ul>
                <a className="text-link-2 mb-3" href="/contact-us/#enquiry">To know more about trading or stock, click here</a>
              </div>
              <div className="col-12 d-block d-lg-none text-center mt-3" data-sal="zoom-in" data-sal-delay="100" data-sal-easing="ease-out">
                <StaticImage width={300} height={302} src="../images/agri-commodity/spices.png" alt="Spices" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 agro-product">
            <div className="row justify-content-center">
              <div className="col-auto" data-sal="fade" data-sal-delay="100" data-sal-easing="ease-out">
                <div className="text-center">
                  <StaticImage className="agro-product-icon" width={130} height={130} src="../images/icons/guar-complex.png" alt="Guar Complex" />
                </div>
                <h2 id="guar-complex">Guar Complex</h2>
                <ul>
                  <li>Guar Seed 10 MT</li>
                  <li>Guar Gum Refined Splits</li>
                </ul>
                <a className="text-link-2 mb-3" href="/contact-us/#enquiry">To know more about trading or stock, click here</a>
              </div>
              <div className="col-12 d-block d-lg-none text-center mt-3" data-sal="zoom-in" data-sal-delay="150" data-sal-easing="ease-out">
                <StaticImage
                  width={300}
                  height={302}
                  src="../images/agri-commodity/guar-complex.png"
                  alt="Guar Complex"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row d-none d-lg-flex text-center mb-6 mt-3">
          <div className="col-lg-4" data-sal="zoom-in" data-sal-delay="100" data-sal-easing="ease-out">
            <StaticImage width={300} height={302} src="../images/agri-commodity/spices.png" alt="Spices" />
          </div>
          <div className="col-lg-4" data-sal="zoom-in" data-sal-delay="150" data-sal-easing="ease-out">
            <StaticImage width={300} height={302} src="../images/agri-commodity/guar-complex.png" alt="Guar Complex" />
          </div>
        </div>
      </>
    </Layout>
  );
};

export default AgriCommodityPage;
